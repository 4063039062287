class activity_clock {
    constructor(){

        var timer;
        var timerStart;
        var timeSpentOnSite = getTimeSpentOnSite();
        var timeSpent;

        function getTimeSpentOnSite(){
            timeSpentOnSite = parseInt(localStorage.getItem('timeSpentOnSite'));
            timeSpentOnSite = isNaN(timeSpentOnSite) ? 0 : timeSpentOnSite;
            return timeSpentOnSite;
        }
        
        function startCounting(){
            timerStart = Date.now();
            timer = setInterval(function(){

                window.requestAnimationFrame(() => {

                    timeSpentOnSite = getTimeSpentOnSite() + (Date.now() - timerStart);
                    localStorage.setItem('timeSpentOnSite',timeSpentOnSite);
                    timerStart = parseInt(Date.now());
                    /* if(parseInt(timeSpentOnSite / 1000) < 300){ 
                        timeSpent = parseInt(timeSpentOnSite / 1000) + 'seconds';
                    }else{ */
                        timeSpent = parseInt((timeSpentOnSite / 1000) / 60) + 'minutes';
                    //};
                    document.getElementById("timer").innerHTML = timeSpent;
                    document.getElementById("timer_message").classList.remove('hidden');

                })

            }, 1000 * 60);
        }

        startCounting();
        var stopCountingWhenWindowIsInactive = true;
        if( stopCountingWhenWindowIsInactive ){
            if( typeof document.hidden !== "undefined" ){
                var hidden = "hidden",
                visibilityChange = "visibilitychange",
                visibilityState = "visibilityState";
            }else if ( typeof document.msHidden !== "undefined" ){
                var hidden = "msHidden",
                visibilityChange = "msvisibilitychange",
                visibilityState = "msVisibilityState";
            }
            var documentIsHidden = document[hidden];
            document.addEventListener(visibilityChange, function() {
                if(documentIsHidden != document[hidden]) {
                    if( document[hidden] ){ clearInterval(timer); }
                    else{ startCounting(); }
                    documentIsHidden = document[hidden];
                }
            });
        }

    }
}

export default activity_clock