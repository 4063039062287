class interactive_title {
    constructor(){

        let regular_title;
        const away_title = "👀 Peek-A-Boo!";
        let is_away = false;
    
        function add_away_message(){
            is_away = true;
            regular_title = document.title;
            document.title = away_title;
        };
    
        window.addEventListener('blur',() =>{
            add_away_message()
        });
    
        function remove_away_message(){
            is_away = false;
            if( document.title == away_title ){
                document.title = regular_title;
            };
        };
    
        window.addEventListener('focus',() =>{
            remove_away_message()
        });

    }
}

export default interactive_title