class scrolling {
    constructor( gsap ) {

        let lastKnownScrollPosition = 0;
        let ticking = false;

        // throttle scrolling event
        document.addEventListener('scroll', (event) => {

            lastKnownScrollPosition = window.scrollY;
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    this.updateScrolling( gsap, lastKnownScrollPosition );
                    ticking = false;
                });
                ticking = true;
            };

        }, false);

    }

    // Function to extract the translation values
    getTranslation(element) {

        const style = window.getComputedStyle(element);
        const matrix = style.transform;

        if (matrix === 'none') {
            return { x: 0, y: 0 }; // No translation applied
        }

        // Extract matrix values (browser outputs in matrix(a, b, c, d, tx, ty) or matrix3d)
        const values = matrix.match(/matrix.*\((.+)\)/)[1].split(', ');

        const is3D = matrix.includes('matrix3d');

        const x = is3D ? parseFloat(values[12]) : parseFloat(values[4]);
        const y = is3D ? parseFloat(values[13]) : parseFloat(values[5]);

        return { x, y };

    }

    updateScrolling( gsap, lastKnownScrollPosition ){

        // basic values
        let moveLink = 0;
        let scrollPosition = window.scrollY;

        // check if navigation is visible and spread it across the screen
        if(document.querySelector('body > aside') && document.querySelector('body > aside').style.display != 'none'){

            const navLinks = document.querySelectorAll('body > aside nav a');
            navLinks.forEach(link => {

                // get target element by ID
                const targetId = link.getAttribute('href');
                const targetElement = document.querySelector(targetId);
                
                // check distances of content
                let targetOffset = parseFloat( targetElement.offsetTop );
                let paddingSection = parseFloat( window.getComputedStyle(targetElement, null).getPropertyValue('padding-top') );
                let paddingHeadline = parseFloat( window.getComputedStyle(targetElement.querySelector(".headlineblock:first-child h2"), null).getPropertyValue('padding-top') );
                
                // check distances of link
                let navigationOffset = parseFloat( document.querySelector('body > aside nav').offsetTop );
                let linkOffset = parseFloat( link.parentNode.offsetTop );
                let offsetdifference = linkOffset - navigationOffset;
                let linkMaxDistance = linkOffset - ( paddingSection + paddingHeadline ) - offsetdifference;

                // calculate how far this link should be moved
                moveLink = ( linkMaxDistance / ( targetOffset - offsetdifference ) ) * scrollPosition * (-1)

                // move link
                if( moveLink <= linkMaxDistance * (-1) ){
                    moveLink = linkMaxDistance * (-1);
                }else if( scrollPosition <= 0 ){
                    moveLink = 0;
                }

                gsap.to(link.parentNode, {
                    y: moveLink,
                    duration: 0.3,
                    ease: "power3.out"
                })

            });

            // check how far last link has been moved and if scroll up should be shown
            const lastLink = document.querySelector('aside > nav > ul > li:last-of-type')
            let lastLinkTranslation = this.getTranslation(lastLink)

            if( (lastLinkTranslation.y * (-1)) >= ( (window.innerWidth / 100 * 3) + 40 ) ){ 
                document.querySelector('aside > nav + div').classList.add("visible") 
            }else{ 
                document.querySelector('aside > nav + div').classList.remove("visible") 
            }
                
        }

    }

}

export default scrolling