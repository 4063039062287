class accordions {
    constructor( gsap, ScrollTrigger ){

        const accordions = document.querySelectorAll('.accordion')

        for(var i = 0; i < accordions.length; i++){
        
            let accordion = accordions[i]
            accordion.previousElementSibling.querySelector('.toggle_accordion').addEventListener('click',(event) =>{
                event.preventDefault()
                this.toggle( gsap, ScrollTrigger, accordion )
            })

        }

    }

    toggle( gsap, ScrollTrigger, accordion ){

        ScrollTrigger.refresh()

        if (accordion.classList.contains("is-collapsed")) {

            accordion.previousElementSibling.querySelector('.toggle_accordion').classList.add("actve")
            accordion.classList.remove("is-collapsed")

            gsap.to(accordion, {
                duration: 0.6,
                height: "auto",
                ease: "power4.out",
                onComplete: function(){
                    ScrollTrigger.refresh()
                    /* gsap.to(window, { 
                        duration: 0.4, 
                        scrollTo: accordion 
                    }) */
                }
            })

            gsap.to(accordion.querySelector('.accordion-inner'), {
                duration: 0.5,
                opacity: 1,
                ease: "power4.out"
            })

        } else { 

            accordion.previousElementSibling.querySelector('.toggle_accordion').classList.remove("actve")
            accordion.classList.add("is-collapsed")
            
            gsap.to(accordion, {
                duration: 0.5, 
                height: 0,
                ease: "power4.out",
                onComplete: function(){
                    ScrollTrigger.refresh()
                }
            })

            gsap.to(accordion.querySelector('.accordion-inner'), {
                duration: 0.4,
                opacity: 0,
                ease: "power4.out"
            })

        }

    }
}

export default accordions