class encode_email {
    constructor(){

        // encode email

        var encEmail = "aGVsbG9AbHVjYXNkaWR0aGlzLmNvbQ==";
        const form = document.getElementById("contact");
        form.setAttribute("title", "send Email");
        form.setAttribute("href", "mailto:".concat(atob(encEmail)));

        /*
        let encEmail = "aGVsbG9AbHVjYXNkaWR0aGlzLmNvbQ=="
        const forms = document.querySelectorAll('a[href="#mailme"]')
        forms.forEach(function (form) {
            form.setAttribute("title", "Email senden")
            form.setAttribute("href", "mailto:".concat(atob(encEmail)))
        })
        */

    }
}

export default encode_email