class scroll_links {
    constructor( gsap ){

        const navLinks = document.querySelectorAll('body > aside nav a');

        navLinks.forEach(link => {
            link.addEventListener('click', event => {
                event.preventDefault();

                // elements
                const targetId = link.getAttribute('href');
                const targetElement = document.querySelector(targetId);

                // distance
                let navigationOffset = parseFloat( document.querySelector('body > aside nav').offsetTop );
                let linkOffset = parseFloat( link.parentNode.offsetTop );
                let offsetdifference = linkOffset - navigationOffset;
                let distance = targetElement.offsetTop - offsetdifference

                // speed
                const targetDistance = Math.abs(window.scrollY - targetElement.offsetTop);
                const duration = 0.5 + ( targetDistance * 0.00016 )

                gsap.to(window, {

                    duration: duration,
                    scrollTo: distance,
                    ease: "power1.inOut"

                });

            });
        });

        const scrollLinks = document.querySelectorAll('a.scrollLink');

        scrollLinks.forEach(link => {
            link.addEventListener('click', event => {
                event.preventDefault();

                // elements
                const targetId = link.getAttribute('href');
                const targetElement = document.querySelector(targetId);

                // distance
                let distance = targetElement.offsetTop
                
                // speed
                const targetDistance = Math.abs(window.scrollY - targetElement.offsetTop);
                const duration = 0.5 + ( targetDistance * 0.00016 )

                gsap.to(window, {

                    duration: duration,
                    scrollTo: distance,
                    ease: "power1.inOut"

                });

            })
        })

    }
}

export default scroll_links