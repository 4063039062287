class experience_icons {
    constructor(){

        document.querySelector(".icn_exploring #mask1 rect").style.width = document.querySelector(".icn_exploring").offsetWidth;

        document.querySelector(".icn_exploring #mask1 rect").style.height = document.querySelector(".icn_exploring").offsetHeight;

        const experiences = document.querySelectorAll(".experience");
        experiences.forEach((experience) => {
            const icn = experience.querySelector(".icon > figure > svg");
            const step = 0.05;
            const minOpacity = 0.45;
            // const maxOpacity = 0.9;
            this.randomizeIcn(icn, step, minOpacity);
            experience.querySelector(".icon > figure").addEventListener("mouseover", (event) => {
                this.randomizeIcn(icn, step, minOpacity);
        });
    })

    }

    randomizeIcn( icn, step, minOpacity ){

        switch(true) {
            case icn.classList.contains("icn_team"):
                let team_paths = icn.querySelectorAll('path');
                let team_opacityValues = [];
                for (let i = 0; i < 10; i++) { team_opacityValues.push(minOpacity + i * step); }
                team_opacityValues.sort(() => Math.random() - 0.5);
                team_paths.forEach((team_path, index) => {
                    team_path.style.opacity = team_opacityValues[index];
                });
                break;
            case icn.classList.contains("icn_teaching"):
                let teaching_paths = icn.querySelectorAll('path');
                let teaching_opacityValues = [];
                for (let i = 0; i < 10; i++) { teaching_opacityValues.push(minOpacity + i * step); }
                teaching_opacityValues.sort(() => Math.random() - 0.5);
                teaching_paths.forEach((teaching_path, index) => {
                    teaching_path.style.opacity = teaching_opacityValues[index];
                });
                break;
            case icn.classList.contains("icn_exploring"):
                function randomCenterOpacity() {
                    return Math.random() * (0.6 - 0.3) + 0.3;
                }
                function randomOuterOpacity() {
                    return Math.random() * (0.3 - 0.1) + 0.1;
                }
                let gradient = document.querySelector('#randomGradient');
                let centerOpacity = randomCenterOpacity();
                let outerOpacity = centerOpacity + randomOuterOpacity();
                gradient.innerHTML = `
                    <stop offset="0%" stop-color="white" stop-opacity="${centerOpacity}" />
                    <stop offset="100%" stop-color="white" stop-opacity="${outerOpacity}" />
                `;
                break;
            case icn.classList.contains("icn_consulting"):
                let consulting_paths = icn.querySelectorAll('path');
                let consulting_opacityValues = [];
                let consulting_step = 0.1;
                let consulting_minOpacity = 0.5;
                for (let i = 0; i < 5; i++) { consulting_opacityValues.push(consulting_minOpacity + i * consulting_step); }
                consulting_opacityValues.sort(() => Math.random() - 0.5);
                consulting_paths.forEach((consulting_path, index) => {
                    consulting_path.style.opacity = consulting_opacityValues[index];
                });
                break;
            case icn.classList.contains("icn_art"):
                let art_paths = icn.querySelectorAll('path');
                let art_opacityValues = [];
                for (let i = 0; i < 10; i++) { art_opacityValues.push(minOpacity + i * step); }
                art_opacityValues.sort(() => Math.random() - 0.5);
                art_paths.forEach((art_path, index) => {
                    art_path.style.opacity = art_opacityValues[index];
                });
                break;
        }

    }
}

export default experience_icons